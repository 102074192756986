import React from 'react'
import { css } from 'emotion'
import { connect } from 'react-redux'
import api from '../services/api'
import spinner from '@pndr/spinner'
class Authenticated extends React.Component {

    state = {
        data: null,
        loading: true
    }

    fetch = async () => {

        const response = await api.request({
            method: 'post',
            data: {
                query: `
                    query info {
                        viewer {
                            schema {
                                tables {
                                    id
                                    name
                                    plural
                                    slug
                                    primaryField
                                    fields {
                                        id
                                        tableId
                                        name
                                        description
                                        type
                                        formulaDependencies
                                        formulaFn
                                        required
                                        settings
                                        virtual
                                        columnWidth
                                    }
                                }
                            }
                        }
                    }
                `
            }
        })

        this.props.dispatch({
            type: 'SET_SCHEMA',
            payload: response.data.data.viewer.schema
        })

        this.setState({
            loading: false
        })
    }

    async componentDidMount() {
        await this.fetch()
    }


    render() {

        if (this.state.loading) {
            return (
                <div
                    className={css`
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                    `}
                >
                    {spinner({ width: 24 })}
                </div>
            )
        }

        return this.props.children
    }
}

export default connect()(Authenticated)