import React from 'react'
import spinner from '@pndr/spinner'
import SegmentedControl from '@pndr/segmented-control'
import Button from '@pndr/button'
import { css } from 'emotion'
import { connect } from 'react-redux'
import Title from '../components/Title'
import Table from '../components/Table'
import Content from '../components/Content'
import Pagination from '../components/Pagination'
import SearchInput from '../components/SearchInput'
import api from '../services/api'
import history from '../services/history'
import pascalcase from 'pascalcase'
import { Search } from 'js-search'
import moment from 'moment'
import Papa from 'papaparse'

const createDefaultState = () => ({
    filteredData: null,
    query: '',
    data: null,
    loading: true,
    loadingView: true,
    offset: 0,
    limit: 250,
    noMatch: false
})

const createDownload = ({ name, encodedURI }) => {

    const link = document.createElement("a")
    link.setAttribute("href", encodedURI)
    link.setAttribute("download", name)
    link.style.display = 'none';
    document.body.appendChild(link) // Required for FF

    link.click() // This will download the data file named "my_data.csv".
}
class TableRoute extends React.Component {

    mounted = true
    search = null

    state = createDefaultState()

    fetch = async () => {

        this.setState({
            loadingView: true
        })

        const tableId = this.props.tableId

        try {
            const response = await api.request({
                method: 'post',
                data: {
                    query: `
                        query table($id: ID!, $offset: Int, $limit: Int) {
                            viewer {
                                table(id: $id) {
                                    records(offset: $offset, limit: $limit)
                                }
                            }
                        }
                    `,
                    variables: {
                        id: this.props.tableId,
                        offset: this.state.offset,
                        limit: this.state.limit
                    }
                }
            })


            if (tableId !== this.props.tableId) {
                // table changed before the data was fetched
                return
            }

            if (!this.mounted) {
                return
            }

            const data = response.data.data.viewer.table.records

            this.search = new Search('id')

            this.props.table.get('fields').forEach(field => {

                let index = field.get('id')

                if (field.get('type') === 'linkToAnotherRecord') {
                    index = field.get('id') + '_name'
                }

                this.search.addIndex(index)
            })

            this.search.addDocuments(data.records);

            this.setState({
                tableId: this.props.tableId,
                data,
                loading: false,
                loadingView: false
            })

            this.refs.table.update()

        } catch (e) {

            this.setState({
                noMatch: true
            })
        }

    }

    async componentDidMount() {
        await this.fetch()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tableId !== this.props.tableId) {
            this.setState(createDefaultState(), () => this.fetch())
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    handleOffsetChange = offset => {

        this.setState({
            offset
        }, () => this.fetch())
    }

    render() {

        if (this.state.noMatch) {

            return (
                <div>
                    <Title>
                        {`Tabel niet gevonden`}
                    </Title>
                    <Content>
                        <h1>
                            Tabel niet gevonden
                        </h1>
                    </Content>
                </div>
            )
        }

        return (
            <React.Fragment>
                {!this.state.loading ? (
                    <Title>
                        {this.props.table.get('plural')}
                    </Title>
                ) : null}
                <div
                    className={css`
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 250px;
                    right: 0;
                    border-left: 1px solid #e0e0e0;
                    overflow: hidden;
                `}
                >
                    <div
                        className={css`
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        height: 70px;
                        display: flex;
                        align-items: center;
                        padding: 0 40px;
                        background-color: #fff;
                    `}
                    >
                        <div
                            className={css`
                            flex-grow: 1;
                        `}
                        >
                            {!this.state.loading ? (
                                <div className={css`font-weight: 700;font-size: 24px;`}>
                                    {this.props.table.get('plural')}
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div
                        className={css`
                        position: absolute;
                        top: 70px;
                        left: 0;
                        right: 0;
                        height: 60px;
                        display: flex;
                        align-items: center;
                        padding: 0 40px;
                        border-top: 1px solid #ebebeb;
                    `}
                    >
                        {!this.state.loading ? (
                            <React.Fragment>
                                <Pagination
                                    recordCount={this.state.data.totalCount}
                                    offset={this.state.offset}
                                    limit={this.state.limit}
                                    onOffsetChange={this.handleOffsetChange}
                                />
                                <div className={css`margin-left: 8px;`}>
                                    <SegmentedControl
                                        value={`${this.state.limit}`}
                                        options={[{
                                            id: '250',
                                            name: '250'
                                        }, {
                                            id: '1000',
                                            name: '1000'
                                        }]}
                                        onChange={this.handleLimitChange}
                                    />
                                </div>
                                <div
                                    className={css`
                                    margin-left: 8px;
                                `}
                                >
                                    <SearchInput
                                        placeholder={'Trefwoord...'}
                                        value={this.state.query}
                                        onChange={this.handleQueryChange}
                                    />
                                </div>
                                {this.state.filteredData ? (
                                    <div
                                        className={css`
                                    font-size: 14px;
                                    background: #fff;
                                    box-shadow: 0 0 0 1px rgba(0,0,0,.09);
                                    height: 30px;
                                    padding: 4px 8px;
                                    align-items: center;
                                    display: flex;
                                    border-radius: 6px;
                                    color: #737373;
                                    margin-left: 8px;
                                    `}
                                    >
                                        {this.state.filteredData.length} resultaten
                                    </div>
                                ) : null}
                                {this.state.loadingView ? (
                                    <div
                                        className={css`margin-left: 16px; display: flex;`}
                                    >
                                        {spinner({ width: 18 })}
                                    </div>
                                ) : null}
                                <div
                                    className={css`
                                    margin-left: auto;
                                `}
                                >
                                    <Button onClick={this.handleDownloadCSV}>
                                        Download CSV
                                </Button>
                                </div>
                            </React.Fragment>
                        ) : null}
                    </div>
                    <div
                        className={css`
                    position: absolute;
                    top: 140px;
                    left: 40px;
                    right: 40px;
                    bottom: 40px;
                    border-radius: 5px;
                    border: 1px solid #ebebeb;
                    overflow: hidden;
                    background-color: #fff;
                    `}
                    >
                        {this.state.loading || this.props.tableId !== this.state.tableId ? (
                            <div className={css`
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    `}>
                                {spinner({ width: 24 })}
                            </div>
                        ) : (
                                <Table
                                    ref={'table'}
                                    tableId={this.props.tableId}
                                    fields={this.props.table.get('fields').toJS()}
                                    onPageRefresh={this.handlePageRefresh}
                                    rows={this.state.filteredData ? this.state.filteredData : this.state.data.records}
                                    onRecordClick={this.handleRecordClick}
                                />
                            )}
                    </div>
                </div>
            </React.Fragment>
        )
    }

    handlePageRefresh = async () => {
        await this.fetch()
    }

    handleDownloadCSV = () => {

        console.log('download csv')

        const rows = this.state.filteredData ? this.state.filteredData : this.state.data.records

        const csv_string = Papa.unparse(rows)

        createDownload({
            name: `${this.props.table.get('name')}_${moment().format('YYYY-MM-DD_hh.mm.ss')}.csv`,
            encodedURI: encodeURI(`data:text/csv;charset=utf-8,` + csv_string)
        })
    }

    handleQueryChange = e => {

        const query = e.target.value

        this.setState({
            query
        })

        let filteredData = null

        if (query.length) {
            filteredData = this.search.search(query)
        }

        console.log(filteredData)

        this.setState({
            query,
            filteredData
        })
    }

    handleLimitChange = ({ value }) => {

        this.setState({
            offset: 0,
            limit: parseInt(value, 10)
        }, () => this.fetch())
    }

    handleSwitchInterface = () => {

        history.push(`/${this.props.table.get('slug')}`)
    }

    handleRecordClick = ({ recordId }) => {

        history.push(`/explorer/${this.props.table.get('slug')}/${recordId}`)
    }
}

export default connect((state, props) => ({
    tableId: pascalcase(props.match.params.id),
    table: state.getIn(['tablesById', pascalcase(props.match.params.id)])
}))(TableRoute)