import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { css } from 'emotion'
import Switch from '@pndr/switch'
import spinner from '@pndr/spinner'
import moment from 'moment'
import numeral from 'numeral'
import dashify from 'dashify'
import ReactMarkdown from 'react-markdown'
import api from '../services/api'
import ProgressBar from './ProgressBar'
import defaultEmptyRenderer from './defaultEmptyRenderer';
import ContractDateIssueIgnoreButton from '../custom-components/ContractDateIssueIgnoreButton'
import IssueResolveButton from '../custom-components/IssueResolveButton'
import ContractDateIssue from './ContractDateIssue'
import { singleSelect } from './SingleSelectField'
import Button from '@pndr/button'
import Checkbox from './Checkbox'
import ReactJson from 'react-json-view'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const CopyLink = ({ value }) => {

    const [copied, setCopied] = useState(false)

    const handleCopy = () => {
        setCopied(true)
        setTimeout(() => 
            setCopied(false),
            2000
        )
    }

    return (
        <CopyToClipboard text={value} onCopy={handleCopy}>
            {copied ? <span className={css`font-size: 12px; padding: 2px 4px; background: rgba(185, 244, 188, 0.2); color: rgb(27, 185, 120); border-radius: 3px; user-select: none;`}>Copied!</span> : copy({ width: 18, className: css`cursor: pointer; &:hover {opactiy: 0.5;}` })}
        </CopyToClipboard>
    )
}

class IFrameViewer extends React.Component {

    state = {
        device: 'desktop'
    }

    render() {

        return (
            <div
                className={css`
                    width: 100%;
                `}
            >
                <div
                    className={css`
                        margin-bottom: 20px;
                        display: flex;
                        align-items: center;
                    `}
                >
                    <button onClick={() => this.setState({ device: 'desktop' })} disabled={this.state.device === 'desktop'} className={css`margin-right: 4px;`}>
                        Desktop
                    </button>
                    <button onClick={() => this.setState({ device: 'mobile' })} disabled={this.state.device === 'mobile'}>
                        Mobile
                    </button>
                    <a href={this.props.src} target="_blank" rel="noopener noreferrer" className={css`margin-left: 20px;`}>Open in nieuwe pagina</a>
                </div>
                <div
                    className={css`
                        width: 100%;
                    `}
                >
                    <iframe
                        className={css`
                    width: ${this.state.device === 'mobile' ? '480px' : '100%'};
                    border: 1px solid #e0e0e0;
                    border-radius: 6px;
                    height: 600px;
                `}
                        src={this.props.src}
                        title={this.props.src}
                    />
                </div>
            </div>
        )
    }
}

const wait = ms => new Promise(resolve => setTimeout(resolve, ms))

const ButtonField = ({ ctx, field, value, source, onPageRefresh }) => {

    const [loading, setLoading] = useState(false)

    const actions = {
        resendMessage: ctx => async (field, value, source) => {

            console.log('send mail for message', source)

            await api.request({
                method: 'post',
                data: {
                    query: `
                        mutation resendMessage($id: ID!) {
                            resendMessage(id: $id)
                        } 
                    `,
                    variables: {
                        id: source.id
                    }
                }
            })

            await wait(1000)
        }
    }

    const handleClick = async (e) => {

        console.log('e', e)

        e.stopPropagation()

        const action = actions[field.settings.action]

        if (!action) {
            throw new Error(`Action with id: ${field.settings.action} not found`)
        }

        setLoading(true)

        await action(ctx)(field, value, source)

        setLoading(false)

        await onPageRefresh()
    }

    return (
        <Button
            icon={loading ? spinner : null}
            onClick={handleClick}
            disabled={loading}
        >
            {loading ? 'Processing...' : field.settings.label}
        </Button>
    )
}

class HtmlPreview extends React.Component {

    state = {
        device: 'desktop'
    }

    render() {

        return (
            <div
                className={css`
                    width: 100%;
                `}
            >
                <div
                    className={css`
                        margin-bottom: 20px;
                        display: flex;
                        align-items: center;
                    `}
                >
                    <button onClick={() => this.setState({ device: 'desktop' })} disabled={this.state.device === 'desktop'} className={css`margin-right: 4px;`}>
                        Desktop
                    </button>
                    <button onClick={() => this.setState({ device: 'mobile' })} disabled={this.state.device === 'mobile'}>
                        Mobile
                    </button>
                </div>
                <div
                    className={css`
                        width: 100%;
                    `}
                >
                    <iframe
                        className={css`
                    width: ${this.state.device === 'mobile' ? '480px' : '100%'};
                    border: 1px solid #e0e0e0;
                    border-radius: 6px;
                    height: 600px;
                `}
                        srcDoc={this.props.value}
                        title={this.props.value}
                    />
                </div>
            </div>
        )
    }
}

const copy = props => (
    <svg {...props} preserveAspectRatio="xMidYMid meet" height="1em" width="1em" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" stroke="currentColor"><g><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></g></svg>
)

const RecordLink = (props) => (
    <Link
        onClick={e => {
            e.stopPropagation()
        }}
        to={props.to}
        title={props.title}
        className={css`
            color: #737373;
            font-weight: bold;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        `}
    >
        {props.children}
    </Link>
)

const date = ctx => ({ field }) => value => {

    let format = value => moment(value).format('D MMMM YYYY')

    if (field.settings.includeTime) {
        format = value => `${moment(value).format('D MMMM YYYY')} om ${moment(value).format('HH:mm')}`
    }

    return (
        <span title={value}>
            {format(value)}
        </span>
    )
}

const renderers = {
    id: ctx => ({ field }) => value => {

        const to = `/explorer/${dashify(field.tableId)}/${value}`

        return (
            <RecordLink to={to} title={value}>
                {value}
            </RecordLink>
        )
    },
    singleLineText: ctx => ({ field }) => value => {

        if (field.settings.ui === 'phone') {

            return (
                <a href={`tel:${value}`}>
                    {value}
                </a>
            )
        }

        if (field.settings.ui === 'email') {

            return (
                <a href={`mailto:${value}`}>
                    {value}
                </a>
            )
        }

        if (field.settings.ui === 'url') {

            return (
                <div
                    className={css`
                        display: flex;
                        align-items: center;
                    `}
                >
                    <a href={value} target="_blank" rel="noopener noreferrer">
                        {value}
                    </a>
                    <div
                        className={css`
                            margin-left: 4px;
                            display: flex;
                            align-items: center;
                        `}
                    >
                    <CopyLink value={value} />
                        </div>
                </div>
            )
        }

        return (
            <span>
                {value}
            </span>
        )
    },
    date,
    updatedTime: date,
    createdTime: date,
    singleSelect,
    json: ctx => ({ field }) => value => {

        if (ctx.type === 'recordPage') {

            return (
                <div
                    className={css`
            background-color: #fff;
            border-radius: 6px;
            overflow: auto;
            max-height: 400px;
            border: 1px solid #f2f2f2;
            font-size: 12px;
            padding: 16px;
        `}
                >
                    <ReactJson src={value} collapsed={1} />
                </div>
            )
        }

        if (!JSON.stringify(value)) {
            console.log('could not stringify', value)
        }

        return (
            <div
                className={css`
                background-color: #fff;
                border: 1px solid #e0e0e0;
                padding: 2px 8px;
                font-size: 80%;
                border-radius: 99999px;
                display: inline-block;
                color: #333;
                font-weight: 700;
            `}
                title={JSON.stringify(value, null, 2)}
            >
                {JSON.stringify(value).substr(0, 50)}
            </div>
        )
    },
    number: ctx => ({ field }) => value => {

        const renderers = {
            percentage: value => numeral(value).format('0.00') + '%',
            currency: value => numeral(value).format('$ 0,0.00'),
            distance: value => `${numeral(value).format('0,0')} km`,
            ProgressBar: value => <ProgressBar percentage={value} />
        }

        const defaultRenderer = value => value

        const renderer = renderers[field.settings.ui] || defaultRenderer

        return renderer(value)
    },
    linkToRemote: ctx => ({ field }) => value => {

        const url = field.settings.slug.replace('{remoteId}', value)

        return (
            <a href={url} target="_blank" rel="noopener noreferrer">
                {value}
            </a>
        )
    },
    linkToAnotherRecord: ctx => ({ field }) => (value, source) => {

        const to = `/explorer/${dashify(field.settings.linkTable)}/${value}`

        return (
            <RecordLink to={to} title={value}>
                {source[field.id + '_name']}
            </RecordLink>
        )
    },
    checkbox: ctx => ({ field }) => value => {

        if (field.settings.ui === 'toggle') {
            return <Switch value={value} width={25} />
        }

        return <Checkbox value={value} width={25} trueLabel={field.settings.trueLabel} falseLabel={field.settings.falseLabel} />
    },
    longText: ctx => ({ field }) => value => {

        if (ctx.type === 'recordPage') {

            if (field.settings.ui === 'console') {

                return (
                    <div
                        className={css`
                        width: 100%;
                        height: 400px;
                        overflow-x: hidden;
                        overflow-y: scroll;
                        background-color: #181818;
                        color: rgb(255, 255, 255, 0.8);
                        white-space: pre-wrap;
                        word-break: break-word;
                        font-family: Monospace, Menlo, "Courier New";
                        padding: 20px;
                        font-size: 14px;
                        border-radius: 3px;
                        `}
                    >
                        {value}
                    </div>
                )
            }

            if (field.settings.ui === 'markdown') {

                return (
                    <ReactMarkdown source={value} />
                )
            }

            if (field.settings.ui === 'html') {

                return (
                    <HtmlPreview value={value} />
                )
            }

            return (
                <div
                    className={css`
                        white-space: pre;
                    `}
                >
                    {value}
                </div>
            )
        }

        return (
            <span>
                {value.substr(0, 50)}
            </span>
        )
    },
    iframe: ctx => () => value => {

        if (ctx.type === 'recordPage') {
            return (
                <IFrameViewer
                    src={value}
                />
            )
        }

        return (
            <a href={value} target="_blank" rel="noopener noreferrer">
                {value}
            </a>
        )
    },
    button: ctx => ({ field, onPageRefresh }) => (value, source) => {
        return (
            <ButtonField ctx={ctx} onPageRefresh={onPageRefresh} field={field} value={value} source={source} />
        )
    }
}

const defaultRenderer = ctx => ({ field }) => () => (
    <span className={css`font-size: 11px;`}>
        {field.type} unsupported
    </span>
)

const customerRenderers = {
    DTGDiffRelativeToYesterday: ctx => () => diff => {

        if (diff === null) {
            return defaultEmptyRenderer()
        }

        const arrowUp = () => <img alt="arrow up" width="12" height="11" src={`${window._env_.REACT_APP_API_URL}/assets/arrow-up.png`} />

        const arrowDown = () => <img alt="arrow down" width="12" height="11" src={`${window._env_.REACT_APP_API_URL}/assets/arrow-down.png`} />

        return <span className={css`color: ${diff === 0 ? '#333' : diff > 0 ? '#c95e22' : '#5cbb26'}; font-weight: bold; padding: 2px 6px; border-radius: 3px; background-color: ${diff === 0 ? '#fbfbfb' : diff > 0 ? 'rgba(201, 94, 34, 0.2)' : 'rgba(92, 187, 38, 0.2)'}; font-size: 13px;`}>{diff === 0 ? '' : diff > 0 ? arrowUp() : arrowDown()}{' '}{diff}</span>
    },
    IssueCount: ctx => () => value => {

        if (!value) {
            return defaultEmptyRenderer()
        }

        return (
            <div
                className={css`
            background-color: #FCF1CD;
            padding: 2px 8px;
            font-size: 85%;
            border-radius: 99999px;
            display: inline-block;
            color: #9C6F19;
            font-weight: 700;
        `}
            >
                <span role="img" aria-label="warning42">⚠️</span> {value} issues
            </div>
        )
    },
    ContractDateIssueIgnoreButton: () => ({ onPageRefresh }) => (id, record) => {

        return (
            <ContractDateIssueIgnoreButton
                id={id}
                stateId={record.stateId}
                onPageRefresh={onPageRefresh}
            />
        )
    },
    IssueResolveButton: () => ({ onPageRefresh }) => (id, record) => {

        return (
            <IssueResolveButton
                id={id}
                stateId={record.stateId}
                resolveType={record.resolveType}
                onPageRefresh={onPageRefresh}
            />
        )
    },
    ContractDateIssues: () => ({ onPageRefresh }) => (id, record) => {

        if (!record.issues.length) {
            return defaultEmptyRenderer()
        }

        return record.issues.map(issue =>
            <div
                className={css`
                    margin-bottom: 4px;
                `}
            >
                <ContractDateIssue issue={issue} />
            </div>
        )
    }
}

export default ctx => ({ field, onPageRefresh }) => {

    let renderer = customerRenderers[field.settings.ui]

    if (!renderer) {
        renderer = renderers[field.type]
    }

    return renderer ? renderer(ctx)({ field, onPageRefresh }) : defaultRenderer(ctx)({ field, onPageRefresh })
}