import React from 'react'
import { css } from 'emotion'
import Button from '@pndr/button'
import Switch from '@pndr/switch'
import api from '../services/api'

export default class DeveloperDatabase extends React.Component {

    state = {
        invalidating: false,
        clearing: false,
        loading: true,
        data: null,
        checked: []
    }

    async componentDidMount() {
        await this.fetch()
    }

    fetch = async () => {

        const response = await api.request({
            method: 'post',
            data: {
                query: `
                    query info {
                        viewer {
                            schema {
                                tables {
                                    id
                                    name
                                    tableName
                                }
                            }
                        }
                    }
                `
            }
        })

        this.setState({
            data: response.data.data.viewer.schema,
            checked: response.data.data.viewer.schema.tables.map(table => table.id),
            loading: false
        })
    }


    render() {

        return (
            <div>
                <h2>Clear the database</h2>
                {this.state.loading ? (
                    <div>
                        Loading...
                        </div>
                ) : (
                        <div>
                            <p>
                                Choose the tables you wish to clear:
                            </p>
                            <div className={css`margin-bottom: 20px;`}>
                                {this.state.data.tables.map(table => (
                                    <div key={table.id} className={css`display: flex; align-items: center; margin-bottom: 8px;`}>
                                        <div className={css`margin-right: 8px`}>
                                            <Switch width={28} value={this.state.checked.includes(table.id)} onChange={({ value }) => this.handleToggle({ tableId: table.id, checked: value })} />
                                        </div>
                                        <div>
                                            {table.name} ({table.tableName})
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className={css`margin-bottom: 50px; display: flex; align-items: center;`}>
                                <div className={css`margin-right: 8px;`}>
                                    <Button onClick={this.handleSelectEssentials}>
                                        Select essential tables
                            </Button>
                                </div>
                                <div className={css`margin-right: 8px;`}>
                                    <Button onClick={this.handleSelectAll}>
                                        Select all
                            </Button>
                                </div>
                                <Button onClick={this.handleDeselectAll}>
                                    Deselect all
                            </Button>
                                <Button onClick={this.handleInvalidateRecords}>
                                    {this.state.invalidating ? 'Invalidating records...' : 'Invalidate records'}
                                </Button>
                            </div>
                            <Button primary onClick={this.handleClearDatabase}>
                                {this.state.clearing ? 'Clearing...' : 'Clear database'}
                            </Button>
                        </div>
                    )}
            </div>
        )
    }

    handleInvalidateRecords = async () => {

        this.setState({ invalidating: true })

        await api.request({
            method: 'post',
            data: {
                query: `
                    mutation invalidateRecords($tables: [ID!]!) {
                        invalidateRecords(tables: $tables)
                    } 
                `,
                variables: {
                    tables: this.state.checked
                }
            }
        })

        this.setState({ invalidating: true })
    }

    handleSelectEssentials = () => {

        this.setState({
            checked: [
                'Contact',
                'Contract',
                'ContractPeriod',
                'DebtorTrajectoryDigestMessage',
                'DebtorTrajectoryMessage',
                'DebtorTrajectory',
                'DebtorTrajectoryReport',
                'Event',
                'Invoice',
                'InvoiceDetail',
                'Payment',
                'Penalty',
                'PenaltyInvoice',
                'Report',
            ]
        })
    }

    handleSelectAll = () => {

        this.setState({
            checked: this.state.data.tables.map(table => table.id)
        })
    }

    handleDeselectAll = () => {

        this.setState({
            checked: []
        })
    }

    handleToggle = ({ tableId }) => {

        console.log(tableId, this.state.checked)

        this.setState({
            checked: this.state.checked.includes(tableId) ? this.state.checked.filter(id => id !== tableId) : ([...this.state.checked, tableId])
        })
    }

    handleClearDatabase = async () => {

        const result = window.prompt('This is a developer action. All data for this application will be permanently deleted. Are you sure? Type CLEAR to confirm this action')

        if (result !== 'CLEAR') {
            return
        }

        this.setState({ clearing: true })

        await api.request({
            method: 'post',
            data: {
                query: `
                    mutation clearDatabase($tables: [ID!]!) {
                        clearDatabase(tables: $tables)
                    } 
                `,
                variables: {
                    tables: this.state.checked
                }
            }
        })

        this.setState({ clearing: false })
    }
}