import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import Layout from './components/Layout'
import Authenticated from './components/Authenticated'
import IndexRoute from './routes/IndexRoute'
import TableRoute from './routes/TableRoute'
import RecordRoute from './routes/RecordRoute'
import ActionsRoute from './routes/ActionsRoute'
import EventsRoute from './routes/EventsRoute'
import DeveloperRoute from './routes/DeveloperRoute'
import SettingsRoute from './routes/SettingsRoute'
import AgendaRoute from './routes/AgendaRoute'
import SignOutRoute from "./routes/SignOutRoute"
import AboutRoute from "./routes/AboutRoute"
import JobQueueRoute from "./routes/JobQueueRoute"
import DocumentationRoute from "./routes/DocumentationRoute"
import history from "./services/history"
import ToggleMutedForDebtorTrajectoryRoute from './routes/ToggleMutedForDebtorTrajectoryRoute'
import ActivatePhaseForDebtorTrajectoryRoute from './routes/ActivatePhaseForDebtorTrajectoryRoute'

import Passport from '@sublayer/passport-components/lib/Passport'
import getSession from '@sublayer/passport-components/lib/getSession'

const NoMatch = () => (
    <div>
        <h1>404 Not Found</h1>
    </div>
)
class App extends React.Component {

    render() {

        const session = getSession()

        return (
            <Passport
                clientUrl={window._env_.REACT_APP_SUBLAYER_PASSPORT_CLIENT_URL}
                clientId={window._env_.REACT_APP_SUBLAYER_PASSPORT_CLIENT_ID}
                redirectUrl={window._env_.REACT_APP_CLIENT_URL}
            >
                <Router history={history}>
                    <Switch>
                        <Route
                            path={'/toggle-muted-for-debtor-trajectory/:id'}
                            exact={true}
                            component={ToggleMutedForDebtorTrajectoryRoute}
                        />
                        <Route
                            path={'/activate-phase-for-debtor-trajectory/:id/:phase'}
                            exact={true}
                            component={ActivatePhaseForDebtorTrajectoryRoute}
                        />
                        <Route
                            path={'/toggle-muted-for-debtor-trajectory/:id'}
                            exact={true}
                            component={ToggleMutedForDebtorTrajectoryRoute}
                        />
                        {!session ? (
                            <Switch>

                            </Switch>
                        ) : (
                                <Authenticated>
                                    <Layout session={session}>
                                        <Switch>
                                            <Route
                                                path={'/'}
                                                exact={true}
                                                component={IndexRoute}
                                            />
                                            <Route
                                                path={'/explorer/:tableId/:recordId'}
                                                exact={true}
                                                component={RecordRoute}
                                            />
                                            <Route
                                                path={'/explorer/:id'}
                                                exact={true}
                                                component={TableRoute}
                                            />
                                            <Route
                                                path={'/agenda'}
                                                exact={true}
                                                component={AgendaRoute}
                                            />
                                            <Route
                                                path={'/actions'}
                                                exact={true}
                                                component={ActionsRoute}
                                            />
                                            <Route
                                                path={'/event'}
                                                exact={true}
                                                component={EventsRoute}
                                            />
                                            <Route
                                                path={'/developer'}
                                                exact={true}
                                                component={DeveloperRoute}
                                            />
                                            <Route
                                                path={'/about'}
                                                exact={true}
                                                component={AboutRoute}
                                            />
                                            <Route
                                                path={'/documentation'}
                                                exact={true}
                                                component={DocumentationRoute}
                                            />
                                            <Route
                                                path={'/job-queue'}
                                                exact={true}
                                                component={JobQueueRoute}
                                            />
                                            <Route
                                                path={'/settings'}
                                                exact={true}
                                                component={SettingsRoute}
                                            />
                                            <Route
                                                path={'/sign-out'}
                                                exact={true}
                                                component={SignOutRoute}
                                            />
                                            <Route
                                                component={NoMatch}
                                            />
                                        </Switch>
                                    </Layout>
                                </Authenticated>
                            )}
                    </Switch>
                </Router>
            </Passport>
        )
    }
}

export default App